<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label for="cp_date">Tanggal</label>
                <Calendar selectionMode="single"  :manualInput="false" v-model="cp_date" :minDate="minDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                <small class="p-invalid" style="color: red" v-if="errorAdd.cp_date" >{{ errorAdd.cp_date[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>EO</label>
                <Dropdown id="eo_id" dataKey="eo_id" v-model="forms.eo_id" :loading="loadingDropdownEO"
                    :options="dataDropdownEO" :class="{ 'p-invalid': errorAdd.eo_id }"
                    optionLabel="eo_name" optionValue="eo_id" placeholder="Pilih EO"
                    :filter="true" :showClear="true" @filter="searchDropdownEO($event, 'add')"
                    @change="changeAreaSampling()" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.eo_id">{{
                    errorAdd.eo_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Area Sampling</label>
                <Listbox v-model="forms.cp_id" dataKey="cp_id" :options="dataDropdownAreaSampling" :class="{ 'p-invalid': errorAdd.cp_id }" :multiple="false" :filter="true" optionLabel="label" optionValue="cp_id" listStyle="max-height:250px" style="width:15rem"  @filter="searchDropdownAreaSampling($event, 'add')">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.label}}</div>
                        </div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.cp_id">{{
                    errorAdd.cp_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Salesman</label>
                <Listbox v-model="forms.mcu_id" dataKey="mcu_id" :options="dataDropdownSalesman" :class="{ 'p-invalid': errorAdd.mcu_id }" :multiple="true" :filter="true" optionLabel="label" listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownSalesman($event, 'add')">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.label}}</div>
                        </div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.mcu_id">{{
                    errorAdd.mcu_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>
<script>

import { mapGetters } from "vuex";
import moment from 'moment';

export default {
    emits: ["submit"],
    data(){
        return{
            // loading
            loading: false,
            loadingDropdownSalesman: false,
            loadingDropdownEO: false,
            loadingDropdownAreaSampling: false,

            // dataDropdown,
            dataDropdownSalesman: null,
            dataDropdownEO: null,
            dataDropdownAreaSampling: null,

            // addNew
            forms: {
                mcu_id: [],
                eo_id: null,
                cp_id: null,
            },
            cp_date: new Date(),
            minDate: new Date(),
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownEO('');
    },
    created(){
    },
    computed:{
        ...mapGetters(['errorAdd']),
        cp_date_label(){ 
            return moment(this.cp_date, 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        convert_mcu_id(){
            return this.forms.mcu_id.map((item) => {
                return item.mcu_id;
            });
        }
    },
    methods: {
        // DROPDOWN
        searchDropdownEO(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dprov.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownEO = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/eo',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownEO = res.data.data;
                        this.loadingDropdownEO = false;
                    } else if (purpose == null) {
                        this.dataDropdownEO = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeAreaSampling() {
            this.forms.cp_id = null;
            this.dataDropdownAreaSampling = null;
            this.searchDropdownAreaSampling('');

            this.forms.mcu_id = [];
            this.dataDropdownSalesman = null;
            this.searchDropdownSalesman('');

        },
        searchDropdownAreaSampling(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.eo_id) {

                    if (valueEdit) {
                        this.$refs.deo.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownAreaSampling = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/sampling',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                        .then(res => {

                            if (purpose == "add") {
                                this.dataDropdownAreaSampling = res.data.data;
                                this.loadingDropdownAreaSampling = false;
                            } else if (purpose == null) {
                                this.dataDropdownAreaSampling = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        searchDropdownSalesman(event, purpose, valueEdit) {
            setTimeout(() => {
                if (this.forms.eo_id) {
                    if (valueEdit) {
                        this.$refs.dsalesman.filterValue = valueEdit;
                    }

                    if (purpose == "add") {
                        this.loadingDropdownSalesman = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/salesman',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "eo_id": this.forms.eo_id,
                        }
                    })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownSalesman = res.data.data;
                            this.loadingDropdownSalesman = false;
                        } else if (purpose == null) {
                            this.dataDropdownSalesman = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/callplan-new/create',
                data: {
                    "cp_id": this.forms.cp_id,
                    "mcu_id": this.convert_mcu_id,
                    "cp_date": this.cp_date_label,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.mcu_id = [];
            this.cp_date = new Date();
            this.forms.eo_id = null;
            this.forms.cp_id = null;
            this.dataDropdownAreaSampling = null;
            this.dataDropdownSalesman = null;
        },
    }
}
</script>